.wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fee140;
  background-image: linear-gradient(333deg, #fee140 42%, #fa709a 100%);

  .login-content {
    background-color: #fff;
    padding: 2rem 3rem;
    box-shadow: 0 0 20px 0 rgba(94, 117, 140, 0.5);
    border-radius: 10px;
    .header {
      text-align: center;
    }
  }
}

.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  margin-bottom: 15px;
  width: 100%;
}
.login-form {
  max-width: 300px;
  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  .ant-divider-inner-text {
    padding: 0 0.5em;
  }

  .login-other {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    // margin-bottom: 0.4rem;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 0.5rem;
      }
    }
  }
  .login-google {
    .login-other;
    color: #fff;
    background: #d44538;
    border-color: #d44538;
    margin-top: 1.5rem;

    &:hover,
    &:active,
    &:focus {
      background: #e24f42;
      color: #fff;
      border-color: #e24f42;
    }
  }
  .login-slack {
    .login-other;
    color: #fff;
    background: #500d50;
    border-color: #500d50;
    &:hover,
    &:active,
    &:focus {
      background: #631b64;
      color: #fff;
      border-color: #631b64;
    }
  }
}

.center {
  text-align: center;
}
.text {
  margin: 0.4rem;
  font-weight: bold;
}

@primary-color: #ff7200;