@import '~antd/dist/antd.less';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #fff;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
}
.vbot-w-full {
  width: 100%;
}
.vbot-text-right {
  text-align: right;
}
.flex {
  display: flex;
}
.vbot-mt-1 {
  margin-top: 1rem;
}
.vbot-relative {
  position: relative;
}
.vbot-absolute {
  position: absolute;
}
.vbot-hidden {
  display: none;
}

@primary-color: #ff7200;