.logo {
  height: 70px;
  //background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    // padding : 1rem;
    height: 100%;
  }
}
.loading {
  background-color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-user {
  text-align: right;
  padding-right: 1rem;
}
.info-plan {
  margin-right: 10px;
}
.site-layout {
  .site-layout-background {
    padding: 0;
    position: sticky;
    z-index: 1;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .header-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-vmo {
        height: 25px;
        margin-left: 1rem;
        cursor: pointer;
      }
    }
  }
}
// [data-theme='dark'] .site-layout .site-layout-background {
//   background: #141414;
// }
.warp-role-member {
  display: flex;
  align-items: center;
  .flag {
    display: inline-block;
    width: 3px;
    border-radius: 2px;
    height: 12px;
    background: #868686;
    margin-right: 8px;
    margin-top: 3px;
    flex-shrink: 0;
  }
  .admin {
    background: #faad14;
  }
  .owner {
    background: #ff7200;
  }
}

//MEMBER INFO
.warp-info-member {
  display: flex !important;
  align-items: center !important;
  flex-direction: row;
  .content {
    display: flex;
    flex-direction: column;
    p {
      margin: 0;
    }
  }
}
.ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
  background-color: green; /*Overriden property*/
}
.is-mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;
}

.ant-dropdown-link {
  &__member-name {
    display: none;
  }
}

@media (min-width: 768px) {
  .site-layout {
    .site-layout-background {
      .header-bar {
        .logo-vmo {
          height: 40px;
        }
      }
    }
  }

  .ant-dropdown-link {
    &__member-name {
      display: initial;
    }
  }
}

@primary-color: #ff7200;