.create-reminder {
  text-align: right;
}

.view-question {
  .question {
    margin: 0;
  }
  .question-hint {
    color: #aaa;
    font-size: small;
    margin: 0;
  }
}
.view-question-modal {
  .ant-modal-body {
    padding: 0 1.5rem;
  }
}

.filter-reminder-title {
  margin-bottom: 16px;
}

.question-pack-list {
  &__header {
    padding-top: 16px;
  }
  &__table {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .title-reminder {
    h3,
    button {
      float: right;
    }
  }
  .ant-row {
    .col-select {
      margin-bottom: 10px;
      flex-direction: row;
      display: flex;
      align-items: center;
      .select {
        flex-grow: 1;
      }
    }
  }
  .ant-row-space-between {
    .title {
      width: 70px;
    }
  }
}

@media (min-width: 768px) {
  .ant-row {
    .col-select {
      .select {
        min-width: 100px;
        flex-grow: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .filter-reminder-title {
    margin-bottom: 0;
  }
}

@primary-color: #ff7200;