.team-list {
  &__header {
    padding-top: 16px;
  }
  &__table {
    margin-top: 16px;
  }
}

.wrap-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .update-btn {
    margin-top: 1rem;
  }
}
.ant-tag-checkable {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}

@primary-color: #ff7200;