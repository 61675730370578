.obstacle-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  // background-color: aqua;
  margin: 1rem;
  // padding: 2.5rem 1rem;
  // border-radius: 50%;
  span {
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    top: -14px;
  }
}
.chart-right {
  text-align: center;
}
.header-report-chart {
  display: flex;
  justify-content: space-between;
}
.report-content {
  border-left: 4px solid lightblue;
  margin: 5px;
  padding: 0 10px;
  p {
    margin: 0;
  }
}
.obstacle {
  border-left: 4px solid lightcoral;
}
.table-ranking-report {
  min-height: 500px;
}

.report-ranking-container {
  margin-top: 16px;
}

@media only screen and (max-width: 767px) {
  .report-chart {
    justify-content: center;
    .chart-left {
      canvas {
        width: 100% !important;
      }
    }
    .header-report-chart {
      h3 {
        text-align: center;
      }
      flex-direction: column;
    }
  }
}

@primary-color: #ff7200;