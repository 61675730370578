.site-page-header {
  border: 1px solid rgb(235, 237, 240);
  padding: 0.5rem 1rem;
  height: 100%;
  // background-color: aquamarine;
  &:hover {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
  }
  .ant-page-header-heading-extra {
    display: flex;
  }
}
.wrap-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 280px;
}

.hint-text {
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0;
  color: #808080b3;
}

@primary-color: #ff7200;