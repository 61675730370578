.member-pagination {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

@media (min-width: 576px) {
  .member-pagination {
    justify-content: flex-end;
  }
}

@primary-color: #ff7200;