.page-header {
  // &__name {
  //   text-align: right;
  // }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > * {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

@media (min-width: 992px) {
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
    &__name {
      margin-bottom: 0;
      text-align: left;
    }
    &__content {
      flex-direction: row;
      align-items: center;
      & > * {
        margin-bottom: 0px;
        margin-left: 8px;
      }
    }
  }
}

@primary-color: #ff7200;