.section-header {
  margin-bottom: 16px;
  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    & > * {
      margin-bottom: 8px;
      max-width: 100%;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

@media (min-width: 768px) {
  .section-header {
    &__content {
      flex-direction: row;
      align-items: flex-start;
      & > * {
        margin-right: 8px;
        min-width: 150px;
        max-width: 300px;
      }
    }
  }
}

@media (min-width: 992px) {
  .section-header {
    &__content {
      flex-grow: 1;
      flex-direction: row;
      padding-right: inherit;
      & > * {
        margin-right: 8px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .section-header {
    display: flex;
    &__name {
      margin-bottom: 0;
    }
    &__content {
      justify-content: space-evenly;
      & > * {
        margin-right: 8px;
        margin-left: 0px;
        &:first-child {
          margin-left: 8px;
        }
      }
    }
  }
}

@primary-color: #ff7200;