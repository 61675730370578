.filter-timesheet {
  padding-top: 16px;
  &__select {
    // overide ant class
    .warp-info-member {
      height: 100%;
      .avt-member {
        display: none;
      }
      p {
        display: none;
      }
    }
  }
}

.request-ranking-header {
  margin-top: 40px;
  .warp-info-member {
    height: 100%;
    .avt-member {
      display: none;
    }
    p {
      display: none;
    }
  }
}

.table-request-ranking {
  margin-top: 16px;
}

.table-timesheet {
  .table-timesheet-filter {
    p {
      margin-bottom: 0;
    }
    b {
      padding-bottom: 8px;
    }
    padding: 10px 0 10px 0;
    .row-filter {
      .select-row {
        margin-bottom: 8px;
        .filter-select {
          width: 100%;
        }
        :first-child {
          .avt-member {
            display: none;
          }
        }
      }
    }
  }

  tr {
    th {
      &:nth-child(1) {
        width: 18%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 13%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 13%;
      }
      &:nth-child(7) {
        width: 8%;
      }
    }
  }
  // .timesheet-charts {
  //   display: flex;
  //   .charts-report-date {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     width: 60%;
  //   }
  //   .pi-charts-timesheet {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     width: 40%;
  //   }
  //   height: 400px;
  //   justify-content: space-between;
  //   align-items: center;
  //   margin: 20px 0 40px 0;
  // }

  .timesheet-charts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;

    .charts-report-date {
      width: 100%;
    }
    .pi-charts-timesheet {
      width: 100%;
    }
  }

  .timesheet-detail-member-table {
    margin-top: 16px;
  }

  .components-table-demo-nested {
    margin-top: 24px;
    tr {
      th {
        &:nth-child(1) {
          width: 5%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 13%;
        }
        &:nth-child(5) {
          width: 13%;
        }
      }
    }
    .col-child-table {
      tr {
        th {
          width: 15%;
        }
      }
    }
  }
  // .table-ranking {
  //   display: flex;
  //   justify-content: space-between;
  //   margin: 20px 0;
  //   align-items: center;
  //   .ranking-top {
  //     display: flex;
  //     align-items: flex-end;
  //     .avt-member {
  //       display: none;
  //     }
  //     .ant-select {
  //       margin: 0 20px 0 20px;
  //     }
  //   }
  // }
  .table-ranking {
    padding-top: 24px;
    .ranking-top {
      & > * {
        margin-top: 8px;
        width: 100%;
      }
      .avt-member {
        display: none;
      }
    }
  }
}
.button-update {
  width: 92%;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 10px;
  }
}
.form-control-date {
  .ant-picker {
    width: 100%;
  }
}
.period-time-picker {
  .ant-picker {
    width: 100%;
  }
}
.member-create {
  .ant-select-selector {
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      .ant-avatar-lg {
        width: 30px;
        height: 30px;
      }
    }
    p {
      margin: 0;
      display: none;
    }
    b {
      line-height: 20px;
    }
  }
}

.warp-role-member {
  display: flex;
  align-items: center;
  font-size: 16px;
  .flag {
    display: inline-block;
    width: 3px;
    border-radius: 2px;
    height: 15px;
    background: #868686;
    margin-right: 8px;
    margin-top: -1px;
    flex-shrink: 0;
  }
  .top-one {
    background: red;
  }
  .top-two {
    background: #c98955;
  }
  .top-three {
    background: #4d9ce6;
  }
}
.report-ranking {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .report-content-request {
    height: 30px;
    border-left: 4px solid lightblue;
    margin: 22px;
    padding: 0px 13px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header-timesheet {
    flex-direction: column;
    align-items: flex-end;
    .header-timesheet-date {
      flex-direction: column;
      align-items: flex-end;
      div {
        margin-bottom: 10px;
      }
    }
  }
  // .table-timesheet-filter {
  //   flex-direction: column !important;
  //   align-items: flex-end;
  //   .row-filter {
  //     margin-top: 15px;
  //     width: 100% !important;
  //     align-items: flex-end !important;
  //     flex-direction: column !important;
  //     .select-row {
  //       margin-bottom: 15px;
  //       .ant-select-multiple {
  //         width: 200px !important;
  //       }
  //     }
  //   }
  // }
  // .timesheet-charts {
  //   flex-direction: column;
  //   height: auto !important;
  //   .charts-report-date {
  //     width: 100% !important;
  //   }
  //   .pi-charts-timesheet {
  //     width: 100% !important;
  //   }
  // }
  // .table-ranking {
  //   align-items: flex-end !important;
  //   flex-direction: column;
  //   .ranking-top {
  //     flex-direction: column;
  //     width: auto !important;
  //     b {
  //       margin-bottom: 10px;
  //     }
  //   }
  //   .ant-picker-middle {
  //     margin-right: 0 !important;
  //     width: 90% !important;
  //     float: right;
  //   }
  //   button {
  //     margin-top: 10px;
  //     float: right;
  //   }
  //   div {
  //     width: 300px !important;
  //     margin-bottom: 5px;
  //   }
  // }

  // .table-ranking {
  //   display: block;
  //   width: 100%;
  // }
  .ant-layout-sider-zero-width-trigger {
    top: 105px;
  }
  .report-ranking {
    width: 90% !important;
  }
}

@media (min-width: 576px) {
  .table-timesheet {
    .table-timesheet-filter {
      .row-filter {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        .select-row {
          width: 33.33%;
          .filter-select {
            width: 90%;
          }
        }
      }
    }

    .table-ranking {
      .ranking-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        & > * {
          width: 40%;
        }
      }
    }

    .timesheet-detail-member-table {
      margin-top: 24px;
    }
  }
}

@media (min-width: 1200px) {
  .table-timesheet {
    .table-timesheet-filter {
      .row-filter {
        .select-row {
          margin-bottom: 0;
          display: flex;
          align-items: flex-start;
          .filter-select {
            max-width: 200px;
          }
          p {
            padding-top: 4px;
            padding-right: 8px;
          }
        }
      }
    }

    .table-ranking {
      .ranking-top {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        & > * {
          max-width: 200px;
        }
      }
    }

    .timesheet-charts {
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      & > * {
        padding: 16px 24px;
      }
      .charts-report-date {
        width: 65%;
      }
      .pi-charts-timesheet {
        width: 35%;
      }
    }
  }
}

@primary-color: #ff7200;