.modal-edit-department-form-item-message {
  .ant-form-item-control {
    position: relative;
  }
  .ant-form-item-extra {
    position: absolute;
    top: 0;
    right: -24px;
  }
  .ant-btn {
    padding: 0;
    border: 0;
    &[disabled] {
      background-color: transparent;
      opacity: 0.7;
    }
  }
}

@primary-color: #ff7200;