.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.form-payment {
  width: 60%;
  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 20px;
    cursor: pointer;
    float: right;
    :hover {
      background-color: #202dbd;
    }
  }
}
#PaymentForm {
  .rccs {
    width: 336px;
    .rccs__card {
      width: 100%;
      margin-left: 22px;
    }
  }
  #control-hooks {
    margin-top: 40px;
    :nth-child(1) {
      .ant-form-item-label {
        width: 110px;
      }
    }
    :nth-child(2) {
      .ant-form-item-label {
        width: 110px;
      }
    }
    .form-payment-number {
      display: flex;
      input[type='number'] {
        width: 130px;
      }
      :nth-child(2) {
        .ant-form-item-label {
          width: 55px;
        }
      }
    }
  }
  .form-update-card {
    height: 40px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    div {
      width: 80%;
      display: flex;
      justify-content: flex-end;
      button {
        margin-left: 20px;
      }
    }
  }
}
.payment2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.payment-email {
  display: flex;
  justify-content: space-between;
}
.button-email {
  width: 85%;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 20px;
  }
}
.des-package {
  .ant-descriptions-item-content {
    div {
      display: flex;
      justify-content: space-between;
      .tag-desc {
        width: 250px;
        padding: 7px;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
}

.period-time-picker {
  .ant-picker {
    width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .payment-email {
    flex-direction: column;
    button {
      width: 50%;
    }
  }
  .allow-feature {
    flex-direction: column;
    button {
      width: 30%;
    }
  }
}

@primary-color: #ff7200;