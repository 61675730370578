.site-layout {
  .site-layout-background {
    background: #fff;
  }
}
.content-scroll {
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

.layout {
  min-height: 100vh;
}

.content {
  margin: 16px;
  &__body {
    padding: 8px;
    height: 100%;
    background-color: #fff;
  }
}

@media (min-width: 768px) {
  .content {
    &__body {
      padding: 16px;
    }
  }
}

@media (min-width: 1024px) {
  .content {
    &__body {
      padding: 24px;
    }
  }
}

@primary-color: #ff7200;