.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .ready-text {
    color: #ff7200;
  }
}

@primary-color: #ff7200;