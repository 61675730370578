.root {
  position: relative;
  .bg-root {
    position: absolute;
    top: 0;
    background-color: #ff7200;
    width: 100%;
    height: 50%;
    z-index: -1;
  }
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1170px;
    padding-bottom: 100px;

    .titles {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .close-button {
        position: absolute;
        left: 0;
        top: 8px;
        cursor: pointer;
        img {
          max-height: 40px;
        }
      }
      .close-button:hover {
        opacity: 0.8;
      }
      h2 {
        text-align: center;
        margin: 50px 0 10px 0;
        font-weight: 700;
        font-size: 48px;
        line-height: 1.5;
        color: white;
      }
      p {
        color: white;
        font-size: 1.14286rem;
        text-align: center;
      }
    }
    .ant-row {
      flex-wrap: nowrap;
    }
    .price {
      border-radius: 200px;
      background: #001529;
      padding: 12px;
      max-width: 130px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      button {
        height: 25px;
        width: 90%;
        .ant-switch-handle {
          width: 20px;
          height: 20px;
        }
        .ant-switch-inner {
          font-size: 15.5px;
        }
      }
    }
    .close-button {
      transform: rotate(180deg);
      position: absolute;
      left: 100px !important;
      top: 65px !important;
      cursor: pointer;
      span {
        font-size: 43px;
        color: white;
      }
    }
    h1 {
      margin-top: 100px;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 30px;
    }
    .packcontainer {
      .info-plan {
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 0px 0px 10px #0000004d;
        flex-direction: column;
        display: flex;
        align-items: center;
        margin-right: 40px;
        .info-price {
          width: 80%;
          text-align: center;
          text-align: -webkit-center;
          font-weight: 600;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          padding-top: 20px;
          padding-bottom: 20px;
          text-transform: uppercase;
          letter-spacing: 1.5px;
          .icon-price {
            background: #ff720033;
            border-radius: 50%;
            height: 70px;
            width: 70px;
            margin: 20px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .header-price {
            display: flex;
            justify-content: center;
            .price-simbol {
              height: 28px;
              font-size: 20px;
              line-height: 30px;
              margin-right: 2px;
              margin-top: 9px;
            }
            .price-value {
              height: 56px;
              font-size: 2.85714rem;
              line-height: 60px;
              margin-right: 0.4rem;
            }
            .price-indicator {
              height: 28px;
              opacity: 0.4;
              line-height: 30px;
              margin-top: auto;
              margin-bottom: 9px;
            }
          }

          h5 {
            width: 80%;
            text-align: center;
            font-size: 24px;
            color: #ff7200;
            font-weight: 600;
          }
          span {
            font-size: 2.85714rem;
            line-height: 60px;
          }
        }

        .info-content {
          display: flex;
          margin-top: 5px;
          flex-direction: column;
          height: 288px;
          p {
            color: #727b8b;
            font-weight: 600;
            padding-bottom: 2px;
            border-bottom: 2px dashed #eb703133;
            font-size: 16px;
            img {
              width: 1rem;
              margin-right: 0.42857rem;
            }
          }
        }
        .upgrade-button {
          width: 60%;
          text-align: center;
          a {
            color: #fff;
            padding: 12px 30px;
            border-radius: 10px;
            background-color: #ff7200;
            display: inline-block;
            margin-bottom: 30px;
            font-size: 1.5em;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            font-size: 15px;
            transition: all 0.15s ease-out;
          }
          a:hover {
            background-color: #d66509;
            transition: all 0.15s ease-out;
          }
        }
        .contact-button {
          width: 60%;
          text-align: center;
          a {
            color: #1e3664;
            padding: 12px 30px;
            border-radius: 10px;
            background: #fff;
            border: 1px solid #c5ced8;
            display: inline-block;
            margin-bottom: 30px;
            font-size: 1.5em;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            font-size: 15px;
            transition: all 0.15s ease-out;
          }
          a:hover {
            opacity: 0.8;
            color: #fff;
            transition: all 0.15s ease-out;
          }
        }
        p {
          margin: 4px;
        }
        button {
          margin-bottom: 36px;
        }
      }
    }
  }
  @media (max-width: 960px) {
    .container {
      .packcontainer {
        .monthlypack {
          margin: 0 0 0 0;
        }
      }
    }
  }
}

@primary-color: #ff7200;