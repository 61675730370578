@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,700;1,300;1,600&display=swap');

.wrap-config {
  background-color: #f8fcff;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 35%;
    h1 {
      font-size: 50px;
      font-weight: 700;
    }
    h3 {
      font-weight: 400;
      color: #889baf;
    }
    .header-img {
      display: flex;
      justify-content: center;
    }
    padding: 20px;
  }
  .login-form-config {
    margin-top: 10px;
    width: 24%;
    height: 44%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .ant-form-item-label {
      width: 100px;
    }
    p {
      text-align: start;
      font-weight: 500;
      &:before {
        content: '*';
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
      }
    }
  }
  .config-form-button {
    width: 100px;
  }
  .ant-form-item {
    flex-direction: column !important;
    .ant-form-item-label {
      text-align: start;
      color: #1a2127;
      font-weight: 500;
    }
  }
}

@primary-color: #ff7200;