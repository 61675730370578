.content-container {
  .content {
    display: flex;
    align-items: baseline;
  }
  .paragraph-1 {
    width: -webkit-fill-available;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      width: 340px;
      height: 220px;
      transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
    }
    img:hover {
      -webkit-transform: scale(1.8);
      transform: scale(1.8);
      z-index: 100;
      transform-origin: bottom;
    }
  }
  .paragraph-2 {
    width: -webkit-fill-available;
    img {
      position: relative;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      width: 495px;
      height: 240px;
      transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
    }
    img:hover {
      -webkit-transform: scale(1.7);
      transform: scale(1.7);
      transform-origin: bottom;
      z-index: 100;
    }
  }
  .paragraph-3 {
    width: -webkit-fill-available;
    .image-container {
      display: flex;

      img:nth-child(1) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 341px;
        height: 235px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(2) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 235px;
        height: 235px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(1):hover {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
        transform-origin: 0 100%;
        z-index: 100;
      }
      img:nth-child(2):hover {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
        transform-origin: 50% 100%;
        z-index: 100;
      }
    }
  }
  .paragraph-4 {
    width: -webkit-fill-available;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      width: 445px;
      height: 210px;
      transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
    }
    img:hover {
      -webkit-transform: scale(1.7);
      transform: scale(1.7);
      transform-origin: bottom;
      z-index: 100;
    }
  }
  .paragraph-5 {
    width: -webkit-fill-available;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      width: 430px;
      height: 245px;
      transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
    }
    img:hover {
      -webkit-transform: scale(1.6);
      transform: scale(1.6);
      transform-origin: bottom;
      z-index: 100;
    }
  }
  .paragraph-6 {
    width: -webkit-fill-available;
    .image-container {
      display: flex;
      img:nth-child(1) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 400px;
        height: 165px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(2) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 296px;
        height: 165px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(1):hover {
        -webkit-transform: scale(2);
        z-index: 100;
        transform: scale(2);
        transform-origin: 0% 100%;
      }
      img:nth-child(2):hover {
        -webkit-transform: scale(2);
        z-index: 100;
        transform: scale(1.9);
        transform-origin: 79% 90%;
      }
    }
  }
  .paragraph-7 {
    width: -webkit-fill-available;
    .image-container {
      display: flex;
      img:nth-child(1) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 315px;
        height: 145px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(2) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 255px;
        height: 145px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(1):hover {
        -webkit-transform: scale(2.6);
        z-index: 100;
        transform: scale(2.6);
        transform-origin: 10% 100%;
      }
      img:nth-child(2):hover {
        -webkit-transform: scale(2.6);
        z-index: 100;
        transform: scale(2.6);
        transform-origin: 75% 100%;
      }
    }
  }
  .paragraph-8 {
    width: -webkit-fill-available;
    .image-container {
      display: flex;
      img:nth-child(1) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 305px;
        height: 275px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(2) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 380px;
        height: 275px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(1):hover {
        -webkit-transform: scale(1.4);
        z-index: 100;
        transform: scale(1.42);
        transform-origin: 50% 100%;
      }
      img:nth-child(2):hover {
        -webkit-transform: scale(1.4);
        z-index: 100;
        transform: scale(1.4);
        transform-origin: 50% 100%;
      }
    }
  }
  .paragraph-9 {
    width: -webkit-fill-available;
    .image-container {
      display: flex;
      img:nth-child(1) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 455px;
        height: 250px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(2) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 400px;
        height: 250px;
        transition: transform 1s cubic-bezier(0, 2.34, 0.58, 1);
      }
      img:nth-child(1):hover {
        -webkit-transform: scale(1.3);
        z-index: 100;
        transform: scale(1.3);
        transform-origin: 0% 100%;
      }
      img:nth-child(2):hover {
        -webkit-transform: scale(1.3);
        z-index: 100;
        transform: scale(1.3);
        transform-origin: 70% 100%;
      }
    }
  }
}
.my-modal-class {
  .ant-modal-footer {
    background-color: #ededed;
  }
  .ant-modal-header {
    background-color: #c0c0c0;
  }
}

@primary-color: #ff7200;